import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import $ from 'jquery';

// CSS
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

// BASE
import Navbar from './base/Navbar';
import Footer from './base/Footer';
import Loader from './base/Loader';
import Modal from './base/Modal';
import { ToolTip } from './base/ToolTip';

// TEMPLATES
import Construction from './pages/templates/Construction';

// LAYOUTS
import ToUpArrow from './layouts/ToUpArrow';

//PAGES
import Index from './pages/Index';
import Blog from './pages/Blog';
import New from './pages/Blog/New';

function App() {

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {

    const handleRouteChange = () => {

      setLoading(true);

    }

    const handleRouteChangeComplete = () => {

      setLoading(false);

    }

    window.addEventListener('beforeunload', handleRouteChange);
    window.addEventListener('load', handleRouteChangeComplete);

    return () => {

      window.removeEventListener('beforeunload', handleRouteChange);
      window.removeEventListener('load', handleRouteChangeComplete);

    }

  })

  function showFooterModal(id) {

    $('.container-modal').css('display', 'flex')
    setModal(id)

  }

  function hideFooterModal() {

    $('.container-modal').css('display', 'none')

  }

  function newMail(t) {

    setText(t);
    $('.tooltip-result').css('visibility', 'visible');
    setTimeout(() => {

      $('.tooltip-result').css('visibility', 'hidden');
      setText("");

    }, 5000)

  }

  return (
    <div className="App" style={{ minHeight: '100vh' }}>
      <BrowserRouter>
        {loading && <Loader />}
        {
          /* 
            <Navbar show={showFooterModal} />
            <Modal type={modal} close={hideFooterModal} newMail={newMail} />
            <ToolTip text={text} />
            <ToUpArrow />
          */
        }
        <Routes>
          {
            /*
              <Route path='*' element={<Navigate to='/' replace />} />
              <Route path='/' element={<Index />} />
              <Route path='/blog/' element={<Blog />} />
              <Route path='/blog/:id/:title' element={<New />} />
            */
          }
          <Route path='*' element={<Navigate to='/' replace />} />
          <Route path='/' exact element={ <Construction /> } />
        </Routes>
        {/*<Footer show={showFooterModal} />*/}
      </BrowserRouter>
    </div>
  );
}

export default App;
