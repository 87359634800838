import React from "react";

// LOGO
import Logo from '../../assets/logo/qya-logo.png';

export default function Construction() {

    return (
        
        <div className="d-flex flex-column align-items-center justify-content-center construction" style={{ minHeight: '100vh' }}>
            <img src={ Logo } />
            <h1>
                EN CONSTRUCCIÓN
            </h1>
        </div>

    )

}